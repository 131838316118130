import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC05fig6a52xaHXCL6SeqFhP4jv2Ni5fkw",
    authDomain: "to-do-app-43071.firebaseapp.com",
    databaseURL: "https://to-do-app-43071.firebaseio.com",
    projectId: "to-do-app-43071",
    storageBucket: "to-do-app-43071.appspot.com",
    messagingSenderId: "181631594199",
    appId: "1:181631594199:web:a2110257229bc5fccc8a92"
  };

class Firebase {
    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.db = firebase.firestore();
        this.timestamp = firebase.firestore.Timestamp();
        this.auth = firebase.auth();
        this.auth_ = firebase.auth;
    }

    

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);


    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => 
        this.auth.currentUser.updatePassword(password);
}

export default Firebase;